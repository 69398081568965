
import {
  SButton,
  SIcon,
  SImageLazy
} from '@strana-artw/ui-kit';
import {
  mapState,
  mapActions,
  storeToRefs
} from 'pinia';
import HeaderPhoneNumber from '@/components/layout/HeaderPhoneNumber.vue';
import HeaderFavoritesIcon from '@/components/common/HeaderFavoritesIcon.vue';
import HeaderNotificationIcon from '@/components/common/HeaderNotificationIcon.vue';
import HeaderProfile from '@/components/common/header-profile';
import HeaderSelectCity from '@/components/common/header-select-city';
import CallbackModal from '@/components/common/modals/CallbackModal.vue';
import HeaderNavigation from '@/components/layout/header/header-navigation/index.vue';
import TheMenuButton from '@/components/layout/TheMenuButton';
import SurveyModal from '@/components/common/survey/SurveyModal.vue';
import { useCitiesStore } from '@/store/cities';
import { useHeaderStore } from '@/store/header';
import { useOfficesStore } from '@/store/offices';
import { useDashboardStore } from '@/store/dashboard';
import { useDashboardSpecsStore } from '@/store/dashboard/specs';
import { useMenuStore } from '@/store/menu';
import { useSurveyStore } from '@/store/survey';
import { useWebView } from '@/shared/lib/composables/useWebView';

export default {
  name: 'TheHeader',

  components: {
    SurveyModal,
    HeaderPhoneNumber,
    HeaderFavoritesIcon,
    HeaderNotificationIcon,
    HeaderProfile,
    HeaderSelectCity,
    CallbackModal,
    HeaderNavigation,
    TheMenuButton,
    SIcon,
    SImageLazy,
    SButton
  },

  setup () {
    const { isModalOpen } = storeToRefs(useSurveyStore());

    const { isWebView } = useWebView();

    return {
      isWebView,
      isSurveyModalOpen: isModalOpen
    };
  },

  data () {
    return {
      logoImage: '/images/developer-logo.svg',
      isCallbackModalOpen: false,
      isMounted: false
    };
  },

  computed: {
    ...mapState(useDashboardSpecsStore, [
      'specs'
    ]),

    ...mapState(useMenuStore, [
      'menu'
    ]),

    ...mapState(useCitiesStore, [
      'cities',
      'currentCity',
      'portalHostWithCity'
    ]),

    ...mapState(useOfficesStore, [
      'phone'
    ]),

    interestsCount () {
      const {
        interestsMine
      } = this.specs ?? {};

      return interestsMine;
    },

    notificationsCount () {
      const {
        notifications
      } = this.specs ?? {};

      return notifications;
    },

    city: {
      get () {
        return this.currentCity?.value;
      },

      set (value) {
        this.setCurrentCity(value);
      }
    },

    loggedIn () {
      return this.$auth.loggedIn;
    },

    isDocumentsPage () {
      return this.$route.path.startsWith('/documents');
    }
  },

  watch: {
    async city (newValue) {
      if (!newValue) {
        return;
      }

      await this.getMenu();
      await this.getSpecs();

      if (this.loggedIn) {
        await this.getDashboard();
      }
    }
  },

  // FixMe: Пришлось перенести получение данных в mounted, чтобы данные из sessionStorage успевали попадать в стор
  async mounted () {
    this.isMounted = true;
    await this.getCities();

    if (!this.currentCity) {
      await this.getCurrentCity();
    }

    await Promise.all([
      this.getMenu(),
      this.getSpecs(),
      this.getOffices()
    ]);
  },

  methods: {
    ...mapActions(useCitiesStore, [
      'getCities',
      'getCurrentCity',
      'setCurrentCity'
    ]),

    ...mapActions(useHeaderStore, [
      'setHeaderMenuState'
    ]),

    ...mapActions(useOfficesStore, [
      'getOffices'
    ]),

    ...mapActions(useDashboardStore, [
      'getDashboard'
    ]),

    ...mapActions(useDashboardSpecsStore, [
      'getSpecs'
    ]),

    ...mapActions(useMenuStore, [
      'getMenu'
    ]),

    CallbackModalOpenHandler () {
      this.isCallbackModalOpen = true;
    }
  }
};
