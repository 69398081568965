
import { PortalTarget } from 'portal-vue';
import { toRefs } from 'vue';
import { useScreenPlugin } from '@/app/plugins/vueScreen';
import TgButton from '@/entities/TgButton/ui/TgButton.vue';
import {
  FLAGS,
  useFeatureFlagsStore
} from '@/store/featureFlags';
import AcceptCookies from '@/components/layout/AcceptCookies.vue';
import { useWebView } from '@/shared/lib/composables/useWebView';

export default {
  name: 'CustomTemplateWithLeftBlock',

  components: {
    AcceptCookies,
    TgButton,
    PortalTarget
  },

  props: {
    asideWidth: {
      type: String,
      required: true
    },

    isHeaderStatic: {
      type: Boolean,
      default: false
    },

    maxContentWidth: {
      type: String,
      default: undefined
    }
  },

  setup () {
    const {
      isTabletOrDesktop
    } = toRefs(useScreenPlugin());

    const { isWebView } = useWebView();

    const { isEnabledFlag } = useFeatureFlagsStore();
    const hasTelegramWidgetFlag = isEnabledFlag(FLAGS.telegramWidget);

    return {
      isWebView,
      isTabletOrDesktop,
      hasTelegramWidgetFlag
    };
  },

  computed: {
    isDisabledAside () {
      return Boolean(this.$route.meta?.isDisabledAside);
    }
  }
};
