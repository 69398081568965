type StorageNames = 'localStorage' | 'sessionStorage'

type TrySaveToStorageResult = {
  success: true;
} | {
  success: false;
  error: Error;
}

function isStorageAvailable (type: StorageNames): boolean {
  try {
    const storage = window?.[type];

    if (!storage) {
      return false;
    }

    const testKey = '__storage_test__';
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);

    return true;
  } catch (error) {
    return false;
  }
}

export function trySaveToStorage (type: StorageNames, key: string, value: string): TrySaveToStorageResult {
  try {
    if (!isStorageAvailable(type)) {
      return {
        success: false,
        error: new Error(`${ type } is not available or blocked.`)
      };
    }

    const storage = window[type];
    storage.setItem(key, value);

    return {
      success: true
    };
  } catch (error: unknown) {
    return {
      success: false,
      error: error as Error
    };
  }
}

type TryGetFromStorageResult = {
    success: true;
    value: string | null | undefined;
} | {
    success: false;
    error: Error;
}

export function tryGetFromStorage (type: StorageNames, key: string): TryGetFromStorageResult {
  try {
    if (!isStorageAvailable(type)) {
      return {
        success: false,
        error: new Error(`${ type } is not available or blocked.`)
      };
    }

    const storage = window[type];
    const value = storage.getItem(key);

    return {
      success: true,
      value
    };
  } catch (error: unknown) {
    return {
      success: false,
      error: error as Error
    };
  }
}
