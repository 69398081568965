import {
  defineStore,
  storeToRefs
} from 'pinia';
import {
  ref
} from '@nuxtjs/composition-api';
import type {
  StateChanger
} from 'vue-infinite-loading';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import type {
  PageInfo
} from '@/types/PageInfo';
import type {
  Document,
  Root,
  DocumentSection,
  DocumentDetail,
  AcceptedDocument
} from '@/types/Documents';
import { useCitiesStore } from '@/store/cities';
import { MENU_TYPE } from '@/constants/menuType';
import {
  useSentry,
  useCabinetsApi
} from '@/app/plugins/app-context';

export const useDocumentsStore = defineStore('documents', () => {
  const $axios: NuxtAxiosInstance = useCabinetsApi();
  const $sentry = useSentry();

  const cities = useCitiesStore();
  const { currentCity } = storeToRefs(cities);
  const documents = ref<Document>({
    page: undefined,
    nextPage: undefined,
    result: []
  });
  const documentSections = ref<Array<DocumentSection>>([]);
  const documentDetail = ref<DocumentDetail>();
  const acceptedDocuments = ref<Array<AcceptedDocument>>([]);

  async function getDocuments (): Promise<void> {
    try {
      if (!currentCity?.value?.value) {
        return;
      }

      const url = '/api/documents/interactions';
      const { data } = await $axios.get<Root>(url, {
        params: {
          city: currentCity.value.value,
          menu_type: MENU_TYPE.LK_CLIENT
        }
      });
      documents.value.result = data.result;
    } catch (error) {
      console.error('🚀 ~ file: documents.ts ~ getDocuments ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getNextPage ({
    page,
    infiniteState
  }: { page: PageInfo; infiniteState: StateChanger }): Promise<void> {
    try {
      if (!page.next_page) {
        return;
      }

      const { data } = await $axios.get<Root>(page.next_page);
      const {
        page_info: pageInfo,
        result
      } = data;

      if (!pageInfo) {
        throw new Error('page info not received');
      }

      if (result?.length) {
        documents.value.page = pageInfo;
        documents.value.nextPage = pageInfo.next_page;
        documents.value.result.push(...result);

        if (!pageInfo.next_page) {
          infiniteState.complete();
        } else {
          infiniteState.loaded();
        }
      } else {
        infiniteState.loaded();
      }
    } catch (error) {
      console.log('🚀 ~ file: documents.ts ~ getNextPage ~ error', error);
      infiniteState.complete();
      $sentry.captureException(error);
    }
  }

  async function getDocumentsList (): Promise<void> {
    try {
      if (!currentCity?.value?.value) {
        $sentry.captureMessage('documents.ts ~ getDocumentsList ~ Город не выбран', {
          tags: {
            cabinet: 'error'
          }
        });

        return;
      }

      const {
        data
      } = await $axios.get<Array<DocumentSection>>('api/legal_documents/list', {
        params: {
          city: currentCity.value.value,
          menu_type: MENU_TYPE.LK_CLIENT
        }
      });

      documentSections.value = data;
    } catch (error) {
      console.log('🚀 ~ file: documents.ts ~ getDocumentsList ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getDocumentDetail (slug: string, version?: number): Promise<void> {
    try {
      if (!currentCity?.value?.value) {
        $sentry.captureMessage('documents.ts ~ getDocumentDetail ~ Город не выбран', {
          tags: {
            cabinet: 'error'
          }
        });

        return;
      }

      const {
        data
      } = await $axios.get<DocumentDetail>('api/legal_documents/detail', {
        params: {
          city: currentCity.value.value,
          slug,
          version
        }
      });

      documentDetail.value = data;
    } catch (error) {
      console.log('🚀 ~ file: documents.ts ~ getDocumentDetail ~ error', error);
      $sentry.captureException(error);
      throw error;
    }
  }

  async function getAcceptedDocuments (slug: Array<string>): Promise<void> {
    try {
      const {
        data
      } = await $axios.get<Array<AcceptedDocument>>('api/legal_documents/accept', {
        params: {
          slug,
          menu_type: MENU_TYPE.LK_CLIENT
        }
      });

      acceptedDocuments.value = data;
    } catch (error) {
      console.log('🚀 ~ file: documents.ts ~ getAcceptedDocuments ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function setAcceptedDocuments (slugs: Array<string>): Promise<void> {
    try {
      await $axios.post('api/legal_documents/accept', {
        menu_type: MENU_TYPE.LK_CLIENT,
        slugs
      });
    } catch (error) {
      console.log('🚀 ~ file: documents.ts ~ setAcceptedDocuments ~ error', error);
      $sentry.captureException(error);
      throw error;
    }
  }

  return {
    documents,
    documentSections,
    documentDetail,
    acceptedDocuments,
    getDocuments,
    getNextPage,
    getDocumentsList,
    getDocumentDetail,
    getAcceptedDocuments,
    setAcceptedDocuments
  };
});
