import { defineStore } from 'pinia';
import type {
  StateObject
} from '@strana-artw/realty';
import { useCitiesStore } from '@/store/cities';
import type {
  FlatParams
} from '@/types/FlatParams';
import type { AppealType } from '@/types/Appeal';
import type { PortalLot } from '@/types/PortalLot';

export const APPEAL_TYPE = {
  CALLBACK: 'callback',
  PARKING: 'parking',
  FAVORITES_FREE_BOOKING: 'favorites_free_booking',
  CREDIT_RATING_REQUEST: 'credit_rating_request',
  OFFER_FREE_BOOKING: 'offer_free_booking'
};

interface AppealAdditional {
  profitBaseId?: number | string;
  offerCollectionId?: number;
}

interface Appeal {
  name: string | null;
  phone: string | null;
  type: AppealType;
  city?: string;
  email?: string;
  description?: string;
  'card_title'?: string;
  additional?: AppealAdditional;
}

interface State {
  realtyForFavoritesFreeBooking?: FlatParams | PortalLot;
  repairDesignerStateObject?: StateObject;
}

export const useAppealStore = defineStore('appeal', {
  state: (): State => ({
    realtyForFavoritesFreeBooking: undefined,
    repairDesignerStateObject: undefined
  }),

  getters: {},

  actions: {
    async sendAppeal (appeal: Appeal): Promise<void> {
      const citiesStore = useCitiesStore();
      const city = citiesStore.currentCity?.value ?? undefined;
      const currentUrl = window.location.href;

      if (!city) {
        return;
      }

      const appealForTicket = {
        ...appeal,
        city
      };

      try {
        await this.$axios.post('api/users/ticket', appealForTicket, {
          headers: { 'Applied-from-url': currentUrl }
        });
      } catch (error) {
        this.$sentry.captureException(error);
        console.log('🚀 ~ file: appeal.ts ~ sendAppeal ~ error', error);
        throw error;
      }
    },

    setRealtyForFavoritesFreeBooking (realtyForFavoritesFreeBooking: FlatParams | PortalLot): void {
      this.realtyForFavoritesFreeBooking = realtyForFavoritesFreeBooking;
    },

    resetRealtyForFavoritesFreeBooking (): void {
      this.realtyForFavoritesFreeBooking = undefined;
    },

    setRepairDesignerStateObject (repairDesignerStateObject: StateObject | undefined): void {
      this.repairDesignerStateObject = repairDesignerStateObject;
    },

    resetRepairDesignerStateObject (): void {
      this.repairDesignerStateObject = undefined;
    }
  }
});
