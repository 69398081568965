const webViewRegex = /WebViewStrana/;
interface webView {
  isWebView: boolean;
}

/**
 * Сделано для приложения Страны.
 * В данный момент ЛК Клиента открывается в webView приложения.
 * Чтобы фронт понимал что сайт открыт в webView,
 * разработчики приложения дописывают строку "WebViewStrana" в userAgent
 * */
export function useWebView (): webView {
  const userAgent = window.navigator.userAgent;

  const isWebView = webViewRegex.test(userAgent);

  return {
    isWebView
  };
}
