import { Context } from '@nuxt/types';
import { useFeatureFlagsStore } from '@/store/featureFlags';

export default async function (ctx: Context): Promise<void> {
  const $auth = ctx?.app?.$auth;

  if (!$auth) {
    return;
  }

  if ($auth.loggedIn) {
    const {
      getFlags
    } = useFeatureFlagsStore();
    await getFlags();
  }
}
