import { defineStore } from 'pinia';
import { useCitiesStore } from '@/store/cities';

interface ClientBonusData {
  amount: number;
  accepted: boolean;
  until: string;
}

interface State {
  clientBonusData?: ClientBonusData;
}

export const useDashboardBonusStore = defineStore('dashboardBonus', {
  state: (): State => ({
    clientBonusData: undefined
  }),

  getters: {},

  actions: {
    async getBonusData (): Promise<void> {
      try {
        const {
          data
        } = await this.$axios.get<ClientBonusData>('api/users/client/bonus');

        this.clientBonusData = data;
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: dashboard/bonus.ts ~ getBonusData ~ e', error);
      }
    },

    async sendAccepted (): Promise<void> {
      const citiesStore = useCitiesStore();
      const city = citiesStore.currentCity?.value ?? undefined;

      if (!city) {
        throw new Error('Не выбран город');
      }

      try {
        const { data } = await this.$axios.post('api/users/client/bonus', {
          city
        });

        this.clientBonusData = data;
      } catch (error) {
        console.log('🚀 ~ file: dashboard/bonus.ts ~ sendAccepted ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    }
  }
});
