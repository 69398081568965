const middleware = {}

middleware['acceptDocuments'] = require('../src/middleware/acceptDocuments.ts')
middleware['acceptDocuments'] = middleware['acceptDocuments'].default || middleware['acceptDocuments']

middleware['featureFlags'] = require('../src/middleware/featureFlags.ts')
middleware['featureFlags'] = middleware['featureFlags'].default || middleware['featureFlags']

middleware['latestFavoritesSync'] = require('../src/middleware/latestFavoritesSync.ts')
middleware['latestFavoritesSync'] = middleware['latestFavoritesSync'].default || middleware['latestFavoritesSync']

middleware['tokenAuth'] = require('../src/middleware/tokenAuth.ts')
middleware['tokenAuth'] = middleware['tokenAuth'].default || middleware['tokenAuth']

export default middleware
