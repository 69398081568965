
import {
  defineComponent,
  type PropType
} from 'vue';
import {
  AreaOutput,
  PlanOutput,
  PriceOutput,
  RoomOutput
} from '@strana-artw/cabinets-common';
import { SIcon } from '@strana-artw/ui-kit';
import {
  type FlatParams
} from '@/types/FlatParams';
import { BOOKING_STATUS } from '@/constants/booking';

export default defineComponent({
  name: 'FavoritesModalBuildingInfo',

  components: {
    SIcon,
    PlanOutput,
    PriceOutput,
    AreaOutput,
    RoomOutput
  },

  props: {
    info: {
      type: Object as PropType<FlatParams>,
      default: null
    }
  },

  computed: {
    isSoonSale () {
      const status = this.info?.status;

      return status === BOOKING_STATUS.isSoonSale;
    }
  }
});
