import { defineStore } from 'pinia';
import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';
import type {
  OnlinePurchaseForm,
  Questionnaire,
  SendQuestionnaireAnswer,
  ParticipantType
} from '@/types/OnlinePurchase';
import type {
  PathType
} from '@/shared/types/openapi/';

interface State {
  onlinePurchaseForm?: OnlinePurchaseForm;
  questionnaires: Array<Questionnaire>;
  participantTypes: Array<ParticipantType>;
  isSmsOk: boolean;
}

export const useOnlinePurchaseStore = defineStore('onlinePurchase', {
  state: (): State => ({
    onlinePurchaseForm: undefined,
    questionnaires: [],
    participantTypes: [],
    isSmsOk: false
  }),

  getters: {},

  actions: {
    setOnlinePurchaseForm (payload: OnlinePurchaseForm): void {
      this.onlinePurchaseForm = payload;
    },

    async sendData (payload: OnlinePurchaseForm): Promise<void> {
      try {
        await this.$axios.post('/api/v2/mortgage/ddu/send_form', payload);
      } catch (error: unknown) {
        console.error('🚀 ~ file: onlinePurchase.ts ~ sendData ~ error', error);
        this.$sentry.captureException(error);
        const errorMessage = getErrorMessageTyped(error);
        throw new Error(errorMessage);
      }
    },

    async getQuestionnaire (bookingId: number): Promise<void> {
      try {
        const { data } = await this.$axios.get<Array<Questionnaire>>('api/agents/questionnaire/online_purchase', {
          params: {
            slug: 'online_purchase',
            booking_id: bookingId
          }
        });

        this.questionnaires = data;
      } catch (error: unknown) {
        console.error('🚀 ~ file: onlinePurchase.ts ~ getQuestionnaire ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async sendQuestionnaireAnswer (questionId: number, payload: { bookingId: number; option: number }): Promise<SendQuestionnaireAnswer | undefined> {
      try {
        const { data } = await this.$axios.post<PathType<'/agents/questionnaire/{question_id}/single_answer/', 'post', 200>>(`api/agents/questionnaire/${ questionId }/single_answer/`, payload);

        if (data) {
          // FIXME: С бэка приходит не правильный тип. Убрать as SendQuestionnaireAnswer, когда будет правильный
          return data as SendQuestionnaireAnswer;
        }

        return;
      } catch (error: unknown) {
        console.error('🚀 ~ file: onlinePurchase.ts ~ sendQuestionnaireAnswer ~ error', error);
        this.$sentry.captureException(error);
        const errorMessage = getErrorMessageTyped(error);
        throw new Error(errorMessage);
      }
    },

    async getParticipantTypes (): Promise<void> {
      try {
        const { data } = await this.$axios.get<Array<ParticipantType>>('api/questionnaire/participant_types');

        this.participantTypes = data;
      } catch (error: unknown) {
        console.error('🚀 ~ file: onlinePurchase.ts ~ getParticipantTypes ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async validateDDUSms (payload: { bookingId: number }): Promise<void> {
      try {
        const { data } = await this.$axios.post('/api/booking/validate_ddu_sms', payload);

        this.isSmsOk = data?.ok;
      } catch (error: unknown) {
        console.error('🚀 ~ file: onlinePurchase.ts ~ validateDDUSms ~ error', error);
        this.$sentry.captureException(error);
        const errorMessage = getErrorMessageTyped(error);
        throw new Error(errorMessage);
      }
    }
  }
});
