import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { DOCUMENT_SLUGS } from '@/constants/documents';
import { useDocumentsStore } from '@/store/documents';

export default defineNuxtMiddleware(async ({ app }) => {
  if (!app.$auth.loggedIn) {
    return;
  }

  const documentsStore = useDocumentsStore();
  const { getAcceptedDocuments, setAcceptedDocuments } = documentsStore;
  await getAcceptedDocuments(DOCUMENT_SLUGS.LOGIN);
  const notAcceptedSlugs = documentsStore.acceptedDocuments
    .filter(({ isAccepted }) => !isAccepted)
    .map(({ documentSlug }) => documentSlug);

  if (notAcceptedSlugs.length) {
    try {
      await setAcceptedDocuments(notAcceptedSlugs);
    } catch (error) {
      console.error('🚀 ~ file: middleware/acceptDocuments.ts ~ setAcceptedDocuments ~ error', error);
    }
  }
});
