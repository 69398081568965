
import { defineComponent } from 'vue';
import {
  storeToRefs
} from 'pinia';
import {
  SButton,
  SIcon
} from '@strana-artw/ui-kit';
import { useDocumentsStore } from '@/store/documents';
import { DOCUMENT_SLUGS } from '@/constants/documents';

export default defineComponent({
  name: 'AcceptCookies',

  components: {
    SButton,
    SIcon
  },

  setup () {
    const documentsStore = useDocumentsStore();
    const {
      getAcceptedDocuments,
      setAcceptedDocuments
    } = documentsStore;
    const {
      acceptedDocuments
    } = storeToRefs(documentsStore);

    return {
      getAcceptedDocuments,
      setAcceptedDocuments,
      acceptedDocuments
    };
  },

  data () {
    return {
      isLoading: true,
      isAccepted: false
    };
  },

  async fetch () {
    if (!this.loggedIn) {
      return;
    }

    this.isLoading = true;
    await this.getAcceptedDocuments(DOCUMENT_SLUGS.COOKIES);

    if (!this.notAcceptedSlugs.length) {
      this.isAccepted = true;
    }
    this.isLoading = false;
  },

  computed: {
    loggedIn () {
      return this.$auth.$state.loggedIn;
    },

    notAcceptedSlugs () {
      return this.acceptedDocuments
        .filter(({ isAccepted }) => !isAccepted)
        .map(({ documentSlug }) => documentSlug);
    }
  },

  methods: {
    async handleClick () {
      if (this.notAcceptedSlugs.length) {
        await this.setAcceptedDocuments(this.notAcceptedSlugs);
      }
      this.isAccepted = true;
    }
  }
});
